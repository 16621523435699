<template>
  <v-row justify="center">
    <v-dialog v-model="shower" width="800px" scrollable persistent>
      <v-card>
        <v-card-title>{{ data.consent.consent_header }}</v-card-title>         
        <v-divider></v-divider>
        <v-card-text class="pa-6" v-if="data.status === 'approve'" v-html='data.consent.consent_detail'></v-card-text>
        <v-card-text class="pa-6" v-else v-html='data.consent.consent_reject'></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close')">{{ $t("admin.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show", "statusconsent"],
  data: function () {
    return {
      data: {
        consent: {
          consent_detail: "",
          consent_header: "",
          consent_reject: "",
          consent_short: "",
        },
        consent_system: "",
        policy: {
          policy_detail: "",
          policy_header: ""
        },
        status: "",
        system_id: ""
      },
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          Object.assign(this.data, this.statusconsent)
        }
        return this.show
      },
    },
  },
  methods: {
  },
};
</script>
<style></style>
